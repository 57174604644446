<template>
  <div class="">
    <AboutUsPageContainer></AboutUsPageContainer>
    <MainFooter></MainFooter>
  </div>
</template>

<script>
import AboutUsPageContainer from '@/views/AboutUsPage/AboutUsPageContainer.vue'
import MainFooter from '@/components/Footers/MainFooter.vue'

export default {
  name: 'AboutUsPage',
  components: {
    AboutUsPageContainer,
    MainFooter
  }
}
</script>

<style scoped></style>
